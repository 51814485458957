<template>
    <div class="page page-profile">
        <div class="page-container container">

            <h2 class="mb-3">{{ $t("personalProfile") }}</h2>
            <ProfileRegistrationForm
                form-type="profile"
                :errors="errors"
                ref="profile_registration_form"
            />

            <h2 class="mt-4 mb-3" v-if="isEditor">{{ $t("professionalProfile") }}</h2>
            <ProfileExtendedForm
                v-if="isEditor"
                page-location="profile"
                :errors="errors"
                ref="profile_extend_form"
            />

            <MainButton
                class="update-profile-button"
                btn-type="submit"
                :btn-text="$t('saveProfile')"
                :is-loading="loaders.updateProfile"
                @click.prevent="submitForm"
            />

            <BecomeAnEditorBanner
                class="mt-5 mb-5"
                v-if="!isEditor"
            />

            <EditorCommission
                v-if="isEditor"
                :entered-iban="enteredIban"
                v-model:credit-info="userData.credit_info"
            />

            <TransactionHistoryList v-if="isEditor" />

            <OrderList />
        </div>
    </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core/dist";
// import {required} from "@vuelidate/validators";
import ProfileExtendedForm from "../../components/profile/ProfileExtendedForm.vue";
import MainButton from "../../components/elements/MainButton.vue";
import userService from "../../functions/services/userService";
import ProfileRegistrationForm from "../../components/profile/ProfileRegistrationForm.vue";
import TransactionHistoryList from "../../components/profile/TransactionHistoryList.vue";
import EditorCommission from "../../components/profile/EditorCommission.vue";
import OrderList from "../../components/profile/OrderList.vue";
import BecomeAnEditorBanner from "../../components/elements/BecomeAnEditorBanner.vue";
import { mapMutations } from "vuex";

export default {
    name: "PageProfile",
    mixins: [ userService ],
    components: {
        BecomeAnEditorBanner,
        OrderList,
        EditorCommission,
        TransactionHistoryList,
        ProfileRegistrationForm,
        MainButton,
        ProfileExtendedForm
    },
    metaInfo() {
        return {
            title: 'Profil',
        }
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            userData: {},
            enteredIban: false,
            loaders: {
                updateProfile: false,
            },
            errors: {}
        };
    },
    mounted() {
        this.getUserProfileData()
    },
    methods: {
        ...mapMutations({
            setUserDataByKey: 'user/setUserDataByKey'
        }),
        getUserProfileData() {
            this.axiosGetUserProfileData().then(res => {
                // console.log('getUserProfileData', res);
                const data = res.data.data

                this.userData = data
                this.$refs.profile_registration_form.prefillUserData(data)
                if (this.isEditor) this.$refs.profile_extend_form.prefillUserData(data)

                // whether the user has an iban saved
                this.enteredIban = !!data.iban?.length
            })
        },
        async submitForm() {
            this.errors = {}

            if (this.isEditor) {
                this.v$.$touch()
                if (this.v$.$invalid) {
                    await this.$nextTick(() => this.scrollToError())
                    return
                }
            } else {
                this.$refs.profile_registration_form.v$.$touch()
                if (this.$refs.profile_registration_form.v$.$invalid) {
                    await this.$nextTick(() => this.scrollToError())
                    return
                }
            }

            this.loaders.updateProfile = true
            try {
                let postData = new FormData()

                // standard user
                const data2 = await this.$refs.profile_registration_form.submitData()
                Object.keys(data2).forEach(key => {
                    postData.set(key, data2[key])
                })

                if (this.isEditor) {
                    const data1 = await this.$refs.profile_extend_form.submitData()
                    Object.keys(data1).forEach(key => {
                        postData.set(key, data1[key])
                    })
                }

                this.postProfileData(postData)
            } catch {
                this.loaders.updateProfile = false
            }
        },
        scrollToError() {
            setTimeout(() => {
                const allErrorDivs = document.querySelectorAll('.error-text') // select all errors divs
                let allErrorDivsCopy = [...allErrorDivs] // copy for array function
                allErrorDivsCopy = allErrorDivsCopy.filter(div => div.textContent) // select only displayed (no empty) errors

                const firstError = allErrorDivsCopy[0]?.parentElement?.parentElement
                if (firstError) firstError.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
            }, 100)
        },
        postProfileData(postData) {
            this.axiosPostUpdateProfile(postData).then(res => {
                // console.log('postProfileData', res);

                const data = res.data.data

                // update user vuex object
                this.setUserDataByKey({ key: 'avatar', value: data.avatar })
                this.setUserDataByKey({ key: 'city', value: data.city })
                this.setUserDataByKey({ key: 'country', value: data.country })
                this.setUserDataByKey({ key: 'first_name', value: data.first_name })
                this.setUserDataByKey({ key: 'last_name', value: data.last_name })
                this.setUserDataByKey({ key: 'name', value: data.name })
                this.setUserDataByKey({ key: 'phone', value: data.phone })
                this.setUserDataByKey({ key: 'role', value: data.role })
                this.setUserDataByKey({ key: 'street', value: data.street })
                this.setUserDataByKey({ key: 'street_number', value: data.street_number })
                this.setUserDataByKey({ key: 'zip_code', value: data.zip_code })
                this.setUserDataByKey({ key: 'iban', value: data.iban })

                // whether the user has an iban saved
                const iban = res.data.data.iban
                this.enteredIban = !!iban?.length

                this.$root.$refs.ref_toast.showToast(res.data?.message, 'toast-success')
            }, e => {
                this.errors = e.response?.data?.errors

                const firstKey = Object.keys(e?.response?.data?.errors)?.[0]
                const message = this.errors[firstKey]?.[0]
                if (message?.length) this.$root.$refs.ref_toast.showToast(message, 'toast-danger')

                this.$nextTick(() => this.scrollToError())
            }).finally(() => {
                this.loaders.updateProfile = false
            })
        }
    },
    validations() {
        return {
            //
        }
    }
};
</script>

<style lang="scss" scoped>
.page-profile::v-deep(.page-container) {
    .component-profile-extended-form {
        margin-top: 20px;
    }

    .update-profile-button {
        margin: 20px 0 60px;
    }
}
</style>